<template>
  <ion-page><cyan-page-wrapper
    title="Detalle de cargo"
    nav1="PARTIDO"
    :nav2="tipoConsejo"
    :ambito="territorio"
    :backRoute="backRoute"
  >
    <div class="memberWrapperOuter">
      <div class="dummyDiv topDummyDiv"></div>
      <div class="memberWrapper">
        <div class="myRow myTitle">
          <div class="foto left">
            <img
              :src="memberData.image || 'assets/images/PersonaSinFoto.svg'"
            />
          </div>
          <div class="nombreycargo right">
            <div class="nombre">
              {{ memberData.nombre }}
            </div>
            <div class="cargo">
              {{ memberData.nombre_cargo }}
            </div>
          </div>
        </div>

        <div class="myRow" v-if="memberData.telefono">
          <div class="caption left">Teléfono de Contacto</div>
          <div class="value right">
            <a :href="telefonoUrl">{{ telefonoGuay }}</a>
          </div>
        </div>

        <div class="myRow" v-if="memberData.email">
          <div class="caption left">Correo</div>
          <div class="value right">
            <a :href="'mailto:' + memberData.email">{{ memberData.email }}</a>
          </div>
        </div>
        <div class="myRow" v-if="memberData.direccion">
          <div class="caption left">Dirección</div>
          <div class="preformatted value right"> 
            {{ memberData.direccion }}  
          </div>
        </div>
      </div>
      <div class="dummyDiv bottomDummyDiv"></div>
    </div>
    <!-- <ion-title>{{ territorio }}</ion-title> -->
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { territorios } from "@/modules/cyanRegions";

export default defineComponent({
  name: "PartyMember",
  components: {
    CyanPageWrapper,
    IonPage
  },
  computed: {
    seed(): any {
          return (this as any).$route.params.seed || 'SV'
    },
    idMember(): any {
        return (this as any).$route.params.idMember || 0;
    },
    memberData(): any {
      const s = store.state.partyLists[this.seed as string];

      if (!s || !s.ok) return {};
      const todosMiembros = [...s.organicos, ...s.coordinaciones];

      for (const i of todosMiembros) {
        if (i.id == this.idMember) {
          return i;
        }
      }

      return {};
      //return (this as any).$route.params.memberData || {};
    },

    backRoute() {
      return "/partyList/" + (this as any) /*.$route.params*/.seed;
    },
    tipoConsejo() {
      const s = this.memberData.nivel || "";
      if (s == "") return "";
      if (s == "SV") return "CONSEJO NACIONAL";
      if (s.length <= 4) return "CONSEJO DEPARTAMENTAL";
      return "CONSEJO MUNICIPAL";
    },

    territorio() {
      if (!this.memberData.nivel) return "";
      const n = this.memberData.nivel as any;
      const t = (territorios as any)[n];
      return t.nombrePadre && t.nombrePadre != t.nombre
        ? t.nombre + ", " + t.nombrePadre
        : t.nombre;
    },
    telefonoGuay() {
      const t = this.memberData.telefono as string;
      if (!t) return '';
      if (!t.match(/^[0-9]{8}$/)) return t;
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    telefonoUrl() {
      const t = this.memberData.telefono as string;
      if (!t || t == '') return '';
      let url = 'tel:';
      if (t.match('/^[0-9]{8}$/')) {
        url += t;
      } else {
        // partir en todo lo que no sean números, espacios, guiones, () y el + inicial

        const tieneMas = (t[0] == '+');

        const tTrabajo = tieneMas ? t.substring(1) : t;

        const piezas = tTrabajo.replace(/[^-() 0-9]/g,'|').split('|') as string[];

        if (tieneMas) url += '+';
        url += piezas[0].replace(/[^0-9]/g,'')
      }

      return url;
    },
    
  },
  methods: {},
});
</script>

<style scoped>
.memberWrapperOuter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  bottom: 0;
  position: absolute;
  top: 0;
  left: 2vw;
  right: 2vw;
}
.memberWrapper {
  padding: 20px 0;
  margin: 20px;
  background: var(--ion-color-primary);
  border-radius: 20px;
  color: white;
  font-size: 120%;
}

.dummyDiv {
  flex: 0 0 10px;
}

.foto img {
  max-width: 120px;
  max-height: 120px;
  height: 120px;
  border-radius: 5px;
}

.nombre {
  font-weight: bold;
  font-size: 200%;
}

.memberWrapper a {
  color: white;
}

.caption {
  font-weight: bold;
}

.value {
  font-weight: 500;
}

.myRow {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 5px;

}

.myTitle {
  align-items: center;
}

.preformatted {
  white-space: pre-wrap; 
}

  .left,
  .right {
    flex: 1 1 800px;
    text-align: center;
    padding: 5px;
  }


@media (min-width: 768px) and (min-height: 500px) {
  .bottomDummyDiv { flex: 0 0 30px; }
  ion-col { text-align: left; }
  .myRow {
    flex-wrap: nowrap;
    padding: 8px 5px;
  }
  .left {
  flex: 0 0 150px;
  }
  .right {
    flex: 1 1 120px;
  }
  .memberWrapper {
    padding: 20px;
  }

}
</style>